<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("Optometrist") }}</label>
      <span>/</span>
      <router-link to="/admin/optometrists">{{ $t("Optometrists") }}</router-link>
      <span>/</span>
      <label active>{{ $t("Details") }}</label>
    </div>

    <v-container>
      <h2>Details</h2>
      <v-row class="mt-5" v-if="patientDetails">
        <v-col cols="4">
          <div class="customCard card1">
            <span class="d-block px-4 pt-4"># <span class="val">{{ patientDetails.id }}</span></span>
            <p>Name: <span class="val">{{ patientDetails.name }}</span></p>
          </div>
        </v-col>
        <v-col cols="4"> 
           <div class="customCard ">
            <p>Mobile: <span class="val">{{ patientDetails.mobile }}</span></p>
          </div>
            <div class="customCard mt-5">
            <p>Birth date: <span class="val">{{ patientDetails.birth_date }}</span></p>
          </div>
          <div class="customCard mt-5">
            <p>Age: <span class="val">{{ patientDetails.age }}</span></p>
          </div>
           </v-col>
        <v-col cols="4"> 
           <div class="customCard">
            <p>Optometrist reports count: <span class="val">{{ patientDetails.count_optometrist_reports }}</span></p>
          </div>
          <div class="customCard mt-5 card1">
            <p>Last date optometrist reports: 
              <span v-if="patientDetails.last_date_optometrist_report" class="d-block pt-4 val">{{ patientDetails.last_date_optometrist_report }}</span>
              <span v-else class="d-block pt-4 emptyVal">Not registered yet</span>
              </p>
          </div>
           </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "patientDetails",
  data() {
    return {
      patientDetails: null,
      id: this.$route.params.id || null,
    };
  },
  methods: {
    changePatient() {
      this.loader = true;
      this.$store
        .dispatch(`patient/findData`, this.id)
        .then((res) => {
          this.patientDetails = res.data;
        })
        .catch((error) => {
          this.notifyCustomError("Error", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
  },
  created() {
    this.changePatient();
  },
};
</script>


<style>
.customCard {
  width: 100%;
  height: 60px;
  background: #edfde4;
  border-radius: 4px;
  color: #222;
  font-weight: bold;
  border: 1px solid #ccc;
}
.card1 {
  height: 100px;
}
.customCard p {
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
}
.val {
  color: blue;
}
.emptyVal {
  color: red;
}
</style>